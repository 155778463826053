// import React, { useEffect, useState } from "react";
// import Navbar from "../Navbar";
// import logo from "../../assets/images/logo.png";
// import { userDetails } from "../../utils";
// import { isAuthenticated } from "../../utils";

// const Index = () => {
//   const [userDate, setUserDate] = useState();
//   const [anchorEl, setAnchorEl] = React.useState(null);

//   useEffect(() => {
//     setUserDate(userDetails());
//   }, [isAuthenticated()]);

//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   return (
//     <div>
//       <div className="header">
//         <div className="logo">
//           <img src={logo} alt="logo" />
//         </div>
//         {isAuthenticated() && (
//           <>

//             <Navbar />
//           </>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Index;

import React, { useEffect, useRef, useState } from "react";
import Navbar from "../Navbar";
import logo from "../../assets/images/logo.png";
import { userDetails } from "../../utils";
import { isAuthenticated } from "../../utils";
import { useLocation, useNavigate } from "react-router-dom";

const Index = () => {
  const [userDate, setUserDate] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showLogOut, setShowLogOut] = React.useState("");
  const logOutRef = useRef(null);
  const location = useLocation()
  const navigate = useNavigate();

  useEffect(() => {
    setUserDate(userDetails());
  }, [isAuthenticated()]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if(showLogOut === "logout_show"){
      setShowLogOut("");  
    }

  }, [location])

  const handleLogOutShow = (e) => {
    setShowLogOut(showLogOut === "" ? "logout_show" : "");
  };



  const LogOut = () => {
    sessionStorage.removeItem("token", { path: "/" });
    sessionStorage.removeItem("agentDetails", { path: "/" });
    sessionStorage.removeItem("userDetails", { path: "/" });
    sessionStorage.removeItem("agentData", { path: "/" });
    sessionStorage.removeItem("userdata", { path: "/" });
    localStorage.removeItem("agentData");
    localStorage.removeItem("userdata");
    navigate("/");
  };

  return (
    <div>
      <div className="header">
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
        {isAuthenticated() && (
          <>
            <div
              class="user-details"
              style={{ cursor: "pointer" }}
              onClick={(e) => handleLogOutShow(e)}
            >
              <span>Welcome</span>
              <strong id="logout">
                &nbsp;
                <em onClick={handleClick}>{userDate?.name}</em>
                &nbsp;
              </strong>
            </div>
            <div className={`logOut_section ${showLogOut}`} ref={logOutRef}>
              <a onClick={() => LogOut()} title="Logout" style={{cursor:"pointer"}}>
                <i class="fa-power-off">&nbsp;</i>{" "}
                <span>Logout</span>
              </a>
            </div>

            <Navbar />
          </>
        )}
      </div>
    </div>
  );
};

export default Index;
